
//DAta in textboxes
const textCoordinates = {
  britishFrequency: {
    textCoordinates: {
      x: 65,
      y: 205
    },
    text: 'FREQUENCY',
    unit: 'Hz'
  },
  britishDemand: {
    textCoordinates: {
      x: 65,
      y: 213
    },
    text: 'DEMAND',
    unit: ' MW'
  },
  britishWind: {
    textCoordinates: {
      x: 65,
      y: 221
    },
    text: 'WIND',
    unit: ' MW'
  },
  britishSolar: {
    textCoordinates: {
      x: 65,
      y: 229
    },
    text: 'Solar',
    unit: ' MW'
  },
  irishFrequency: {
    textCoordinates: {
      x: 20,
      y: 310
    },
    text: 'FREQUENCY',
    unit: 'Hz'
  },
  irishDemand: {
    textCoordinates: {
      x: 20,
      y: 318
    },
    text: 'DEMAND',
    unit: ' MW'
  },
  irishWind: {
    textCoordinates: {
      x: 20,
      y: 326
    },
    unit: ' MW',
    text: 'WIND'
  },
  irishSnsp: {
    textCoordinates: {
      x: 20,
      y: 334
    },
    unit: '%',
    text: 'SNSP'
  },
  europeanFrequency: {
    textCoordinates: {
      x: 244,
      y: 355
    },
    unit: 'Hz',
    text: 'ENTSO-E Freq'
  },
}
export default textCoordinates;
